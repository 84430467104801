import React, { Suspense, useEffect, useState } from 'react';
import { Breadcrumb, Header, Sidebar } from '../../components';
import * as S from './list-report-attendance.styled';
import Sdk from "api.digitalpages.module.sdk.api";
import Loading from '../../components/loading';
import AttendanceTable from './attendance-table';
import downloadUserFrequencyCSV from "../../utils/downloadUserFrequencyCSV";
import Logo from "./ecc-logo.png";
import LogoRound from "./ecc-logo-round.png";

export default function ViewReportScores(props) {
  const {
    consumer: {
      currentRoute = null,
      currentGroup = null,
      defineRoute = null,
      defineGroup = null,
      groups = null,
    },
  } = props;
  const [registerUid, setRegisterUid] = useState(null);
  const [renderCertificate, setRenderCertificate] = useState(false);
  const [certificateData, setCertificateData] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const { user } = Sdk.authorization;

  useEffect(() => {
    if (defineRoute !== null && typeof defineRoute === 'function') {
      const { courseUid, registerUid } = props.match.params;
      if (courseUid && registerUid) {
        defineRoute({ name: 'Relatório de frequência', uri: `/report/attendance/course/${courseUid}/register/${registerUid}` });
      } else {
        defineRoute({ name: 'Relatório de frequência', uri: '/report/attendance' });
      }
    }
  }, []);

  const addScript = async (url) => {
    var script = document.createElement('script');
    script.type = 'application/javascript';
    script.src = url;
    document.head.appendChild(script);
  }

  useEffect(() => {
    addScript('https://raw.githack.com/eKoopmans/html2pdf/master/dist/html2pdf.bundle.js');
  }, []);

  const getUserFrequency = async (registerUid) => {
    var filter = "";

    if (user.roles) {
      for (const role of user.roles) {
        if (
          role.type ==="TeamAdmin"
        ) {
          filter = "&filter_entities=true"
        }
      }
    }
    try {
      const resp = await Sdk.dynamic.bridge(`cms/v1.0/learning/register/uid/${registerUid}/report/frequency/management?page_size=100000000${filter}`);
      const users = await Sdk.dynamic.bridge(`auth/v1.0/project/uid/${Sdk.Api.authorization.activeProject.uid}/users`);
      const registers = await Sdk.dynamic.bridge(`cms/v1.0/learning/course/uid/${props.match.params.courseUid}/registers?page_size=10000000${filter}`);
      const register = registers.result.find(reg => reg.uid === registerUid);

      return {
        "frequency": resp,
        "users": users,
        "register": register
      };
    } catch (error) {
      console.error(error);
    }
  }

  const splitRows = (items) => {
    var allArrays = [];
    var firstArr = items.slice(0, 10);
    var secondArr = items.slice(11, items.length);

    allArrays.push(firstArr);

    var size = 18;
    for (var i = 0; i < secondArr.length; i += size) {
      allArrays.push(secondArr.slice(i, i + size));
    }

    console.log("SLICED", allArrays);

    return allArrays;
  }

  const getCertificate = (data) => {
    var users = data.users;
    var result = data.frequency.result;
    var completed = result.filter(item => item.activities_count === item.completed_activities_count);

    for (var i = 0; i < completed.length; i++) {
      for (var j = 0; j < users.length; j++) {
        if (completed[i].user_uid === users[j].uid) {
          completed[i].user = users[j];
        };
      };
    };

    var inactiveUsers = completed.filter(i => i.user === undefined);

    var activeUsers = completed.filter(i => i.user !== undefined);
    activeUsers = activeUsers.sort(function(a, b) {
      var textA = a.user.detail.name.toUpperCase();
      var textB = b.user.detail.name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    var splitedItems = splitRows(activeUsers)

    setRenderCertificate(true);
    setCertificateData({
      "register": data.register,
      "course_title": completed[0].course_title,
      "course_uid": completed[0].course_uid,
      "groups": splitedItems,
      "completed_active_users": activeUsers.length,
      "completed_inactive_users": inactiveUsers.length
    })
  }

  const formatDate = (str) => {
    if (str === null) return "-";
    var items = str.split("T")[0].split("-");
    return `${items[2]}/${items[1]}/${items[0]}`;
  }

  const getCurrentDate = () => {
    var dt = new Date()
    return dt.toLocaleString();
  }

  const onCloseCertificate = () => {
    setDownloading(false);
    setRenderCertificate(false);
    setCertificateData(null);
  }

  const downloadPDF = (elements, pdfOptions) => {
    let worker = window.html2pdf().set(pdfOptions).from(elements[0]);

    if (elements.length > 1) {
      worker = worker.toPdf();

      elements.slice(1).forEach((element, index) => {
        worker = worker.get('pdf').then(pdf => {
          pdf.addPage()
        }).from(element).toContainer().toCanvas().toPdf();
      })
    }

    worker = worker.save()

    // setDownloading(false);
  }


  const getCertificatePdf = async () => {
    setDownloading(true);
    const pages = Array.from(document.querySelectorAll(".element-to-print"));
    const pdfOptions = {
      "margin": 0,
      "filename": "certificado-comprobatorio.pdf",
      "image": {
        "type": "jpeg",
        "quality": 1
      },
      "html2canvas": {
        "scale": 2
      },
      "jsPDF": {
        "unit": "in",
        "format": "letter",
        "orientation": "portrait"
      }
    }

    await downloadPDF(pages, pdfOptions);
  }

  return (
    <S.MainContainer className='rdp-admin-view-report-scores'>
      <Header />

      <Sidebar
        currentRoute={currentRoute}
        defineGroup={defineGroup}
        defineRoute={defineRoute}
        groups={groups}
      />

      <Breadcrumb
        currentRoute={currentRoute}
        currentGroup={currentGroup}
        importDownloadFrequenciesDataEnabled
        importDownloadFrequenciesDataOnClick={(exportAllUserData) => {
          return new Promise((resolve, reject) => {
            if (registerUid !== null) {
              downloadUserFrequencyCSV(registerUid, exportAllUserData).then(resolve);
            } else {
              resolve();
            }
          });
        }}
        importDownloadCertificateEnabled={true}
        importDownloadCertificateOnClick={() => {
          return new Promise(async (resolve, reject) => {
            if (registerUid !== null) {
              var data = await getUserFrequency(registerUid);
              getCertificate(data);
              resolve(data);
            } else {
              resolve();
            }
          });
        }}
        label='Relatórios'
      />

      <S.Content className='rdp-admin-view-report-scores-content'>
        <S.Card>
          <Suspense fallback={<Loading />}>
            <AttendanceTable
              onUpdateRegister={(uid) => setRegisterUid(uid)}
              match={props.match}
            />
          </Suspense>
        </S.Card>
      </S.Content>

      {renderCertificate &&
        <S.CertificateModalContainer>
          <S.CertificateModalOverlay></S.CertificateModalOverlay>
          <S.CertificateModal>
            <S.CertificateModalHeader>

              {downloading &&
                <S.CertificateModalHeaderSaveBtn>Aguarde... Gerando certificado comprobatório em pdf.</S.CertificateModalHeaderSaveBtn>
              }

              {!downloading &&
                <S.CertificateModalHeaderSaveBtn onClick={() => getCertificatePdf()}>Baixar certificado comprobatório em pdf</S.CertificateModalHeaderSaveBtn>
              }

              {/* <button onClick={() => window.html2pdf(document.getElementById('element-to-print'))}>Baixar pdf</button> */}
              <S.CertificateModalClose onClick={() => onCloseCertificate()}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="768" height="768" viewBox="0 0 768 768">
                  <path fill="#000" d="M607.5 205.5l-178.5 178.5 178.5 178.5-45 45-178.5-178.5-178.5 178.5-45-45 178.5-178.5-178.5-178.5 45-45 178.5 178.5 178.5-178.5z"></path>
                </svg>
              </S.CertificateModalClose>
            </S.CertificateModalHeader>
            <S.CertificateModalBody>

              {certificateData && certificateData.groups && certificateData.groups.map((group, index) => (
                <>
                  {index === 0 &&
                    <S.CertificateContainer className="element-to-print">
                      <S.CertificateHeader>
                        <S.CertificateHeaderLogo src={Logo}/>
                        <S.CertificateHeaderTitle>CERTIFICADO DE CONCLUSÃO</S.CertificateHeaderTitle>

                      </S.CertificateHeader>
                      <S.CertificateHeader>
                        <S.CertificateLabel><b>Empresa:</b> {Sdk.Api.authorization.activeProject.name}</S.CertificateLabel>
                        <S.CertificateLabel><b>Curso:</b> {certificateData.course_title}</S.CertificateLabel>
                        <S.CertificateLabel><b>Turma:</b> {certificateData.register.name}</S.CertificateLabel>
                        <S.CertificateLabel><b>Identificador do curso:</b> {certificateData.course_uid}</S.CertificateLabel>
                        <S.CertificateLabel><b>Data e hora de geração do certificado:</b> {getCurrentDate()}</S.CertificateLabel>
                        <S.CertificateLabel><b>Certificado gerado por:</b> {Sdk.Api.authorization.user.login}</S.CertificateLabel>
                        <S.CertificateLabel><b>Nº de usuários ativos na base que concluíram o curso:</b> {certificateData.completed_active_users}</S.CertificateLabel>
                        {/* <S.CertificateLabel><b>Nº de usuários excluídos da base que concluíram o curso:</b> {certificateData.completed_inactive_users}</S.CertificateLabel> */}
                      </S.CertificateHeader>
                      <S.CertificateModalItems style={{backgroundImage: `url(${LogoRound})`}}>
                        <S.CertificateModalItem>
                          <S.CertificateModalItemFieldHeader style={{width: "40%"}}>Nome do colaborador</S.CertificateModalItemFieldHeader>
                          <S.CertificateModalItemFieldHeader style={{width: "24%"}}>Login de acesso</S.CertificateModalItemFieldHeader>
                          <S.CertificateModalItemFieldHeader style={{width: "18%"}}>Data de início</S.CertificateModalItemFieldHeader>
                          <S.CertificateModalItemFieldHeader style={{width: "18%"}}>Data de conclusão</S.CertificateModalItemFieldHeader>
                        </S.CertificateModalItem>
                        {group && group.map((item, index) => (
                          <S.CertificateModalItem key={index}>
                            <S.CertificateModalItemField style={{width: "40%"}}>{item.user && item.user.detail.name}</S.CertificateModalItemField>
                            <S.CertificateModalItemField style={{width: "24%"}}>{item.user && item.user.login}</S.CertificateModalItemField>
                            <S.CertificateModalItemField style={{width: "18%"}}>{formatDate(item.start_course)}</S.CertificateModalItemField>
                            <S.CertificateModalItemField style={{width: "18%"}}>{formatDate(item.end_course)}</S.CertificateModalItemField>
                          </S.CertificateModalItem>
                        ))}
                      </S.CertificateModalItems>
                      <S.CertificatePage>1</S.CertificatePage>
                    </S.CertificateContainer>
                  }
                  {index > 0 &&
                    <S.CertificateContainer className="element-to-print">
                      <S.CertificateModalItems style={{backgroundImage: `url(${LogoRound})`}}>
                        {group && group.map((item, index) => (
                          <S.CertificateModalItem key={index}>
                            <S.CertificateModalItemField style={{width: "40%"}}>{item.user && item.user.detail.name}</S.CertificateModalItemField>
                            <S.CertificateModalItemField style={{width: "24%"}}>{item.user && item.user.login}</S.CertificateModalItemField>
                            <S.CertificateModalItemField style={{width: "18%"}}>{formatDate(item.start_course)}</S.CertificateModalItemField>
                            <S.CertificateModalItemField style={{width: "18%"}}>{formatDate(item.end_course)}</S.CertificateModalItemField>
                          </S.CertificateModalItem>
                        ))}
                      </S.CertificateModalItems>
                      <S.CertificatePage>{index + 1}</S.CertificatePage>
                    </S.CertificateContainer>
                  }
                </>
              ))}


              {/* {certificateData &&
                <S.CertificateContainer id="element-to-print">
                  <S.CertificateHeader>
                    <S.CertificateHeaderLogo src={Logo}/>
                    <S.CertificateHeaderTitle>CERTIFICADO DE CONCLUSÃO</S.CertificateHeaderTitle>

                  </S.CertificateHeader>
                  <S.CertificateHeader>
                    <S.CertificateLabel><b>Empresa:</b> {Sdk.Api.authorization.activeProject.name}</S.CertificateLabel>
                    <S.CertificateLabel><b>Nome do curso:</b> {certificateData.course_title}</S.CertificateLabel>
                    <S.CertificateLabel><b>Identificador do curso:</b> {certificateData.course_uid}</S.CertificateLabel>
                    <S.CertificateLabel><b>Data e hora de geração do certificado:</b> {getCurrentDate()}</S.CertificateLabel>
                    <S.CertificateLabel><b>Certificado gerado por:</b> {Sdk.Api.authorization.user.login}</S.CertificateLabel>
                  </S.CertificateHeader>
                  <S.CertificateModalItems style={{backgroundImage: `url(${LogoRound})`}}>
                    <S.CertificateModalItem>
                      <S.CertificateModalItemFieldHeader style={{width: "35%"}}>Nome do colaborador</S.CertificateModalItemFieldHeader>
                      <S.CertificateModalItemFieldHeader style={{width: "25%"}}>Login de acesso</S.CertificateModalItemFieldHeader>
                      <S.CertificateModalItemFieldHeader style={{width: "20%"}}>Data de início</S.CertificateModalItemFieldHeader>
                      <S.CertificateModalItemFieldHeader style={{width: "20%"}}>Data de conclusão</S.CertificateModalItemFieldHeader>
                    </S.CertificateModalItem>
                    {certificateData.items && certificateData.items.map((item, index) => (
                      <S.CertificateModalItem key={index}>
                        <S.CertificateModalItemField style={{width: "35%"}}>{item.user && item.user.detail.name}</S.CertificateModalItemField>
                        <S.CertificateModalItemField style={{width: "25%"}}>{item.user && item.user.login}</S.CertificateModalItemField>
                        <S.CertificateModalItemField style={{width: "20%"}}>{formatDate(item.start_course)}</S.CertificateModalItemField>
                        <S.CertificateModalItemField style={{width: "20%"}}>{formatDate(item.end_course)}</S.CertificateModalItemField>
                      </S.CertificateModalItem>
                    ))}
                  </S.CertificateModalItems>
                </S.CertificateContainer>
              } */}
            </S.CertificateModalBody>
          </S.CertificateModal>
        </S.CertificateModalContainer>
      }
    </S.MainContainer>
  );
}